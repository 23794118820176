<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >

      <v-text-field
        v-model="mainImage"
        :counter="1000"
        :rules="logoRules"
        label="Image principale"
        required
      ></v-text-field>

      <v-btn
        :disabled="!valid"
        color="success"
        class="mr-4"
        @click="validate"
      >
        Validate
      </v-btn>

      <v-btn
        color="error"
        class="mr-4"
        @click="reset"
      >
        Reset Form
      </v-btn>

      <v-btn
        color="warning"
        @click="resetValidation"
      >
        Reset Validation
      </v-btn>
    </v-form>
    <div class="main-image-display">
      <img
        :src="mainImage"
        style="width: 100%"
        alt="Image en entête de l'app sonnette">
    </div>
  </div>
</template>

<script>
// import AddCompany from '../graphQL/AddCompany.gql';
import gql from 'graphql-tag';

export default {
  data: () => ({
    valid: true,
    mainImage: '',
    nameRules: [
      (v) => !!v || 'Name is required',
      (v) => (v && v.startsWith('http')) || 'Logo must be a valid url',
    ],
  }),

  methods: {
    validate() {
      this.$refs.form.validate();
      this.createMainImage();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async createMainImage() {
      await this.$apollo.mutate({
        mutation: gql`
          mutation updateOneHome ($query: HomeQueryInput, $set: HomeUpdateInput!) {
            updateOneHome (query: $query, set: $set) {
              homeImage
            }
          }`,
        variables: {
          query: {
            _id: '601ab3440c2580f009a371c4',
          },
          set: {
            homeImage: this.mainImage,
          },
        },
      });
      this.$emit('mainImageFormSent');
    },
  },
};
</script>

<style scoped>
.main-image-display {
  margin-top: 2em;
  display: flex;
  justify-content: center;
}
</style>
