<template>
  <div>
    <main-image-btn
      class="form-btn"
      @refresh="refreshData"
    />
    <!-- <p>{{homes[0].homeImage}}</p> -->
    <div class="image-home">
      <img
        :src="homes && homes[0] ? homes[0].homeImage : 'https://via.placeholder.com/1500C/O https://placeholder.com/'"
        style="object-fit: cover; width: 100%"
        alt="Image d'entête de la sonnette">
    </div>
  </div>
</template>

<script>
import HOMES from '../graphQL/Homes.gql';
import MainImageBtn from './MainImageBtn.vue';

export default {
  components: {
    MainImageBtn,
  },
  data() {
    return {
      msg: 'Hi there!',
    };
  },
  apollo: {
    homes: HOMES,
  },
  methods: {
    refreshData() {
      console.log('REFRESH');
      this.$apollo.queries.homes.refetch();
    },
  },
};
</script>

<style scoped>
.form-btn {
  display: flex;
  justify-content: flex-end;
}

.image-home {
  display: flex;
  justify-content: center;
  margin: 2em 0;
  border: 1px solid lightgray;
  max-height: 40vh;
}
</style>
