<template>
  <div class="pa-12">
    <main-image-organism></main-image-organism>
    <company-organism/>
  </div>
</template>

<script>
import MainImageOrganism from '../components/MainImageOrganism.vue';
import CompanyOrganism from '../components/CompanyOrganism.vue';

export default {
  components: {
    CompanyOrganism,
    MainImageOrganism,
  },
  data() {
    return {
      msg: 'Hi there!',
    };
  },
};
</script>

<style scoped>
.super-class {
  background: #4b79b6;
}
</style>
