<template>
  <div>
    <FilterTable
      :dataTable="homes ? homes[0].companies : []"
      :fieldsToMatch="['name', 'email']"
      @filteredMatch="foundedData"
    />
    <!-- <p>{{ homes[0]._id }}</p> -->
    <div>
      <v-data-table
        :headers="headers"
        :items="founded"
        :items-per-page="10"
        class="elevation-1"
      >
      <template v-slot:item.logo="{ item }">
          <img :src="item.logo" style="width: 50px; height: 50px" />
      </template>
      <template v-slot:item.action="{ item }">
        <!-- <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon> -->
        <v-icon color="red" @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import COMPANIES from '../graphQL/Users.gql';
import HOMES from '../graphQL/Homes.gql';
import FilterTable from './FilterTable.vue';

export default {
  components: {
    FilterTable,
  },
  data() {
    return {
      // villageId: this.$apollo.homes[0]._id || null,
      headers: [
        {
          text: 'Société',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Email', value: 'email' },
        { text: 'logo', value: 'logo' },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      founded: [],
    };
  },
  apollo: {
    companies: COMPANIES,
    homes: HOMES,
  },
  watch: {
    refresh() {
      this.refreshData();
    },
  },
  methods: {
    refreshData() {
      console.log('REFRESH');
      this.$apollo.queries.homes.refetch();
    },
    async deleteItem(item) {
      const { companyId } = item;
      const villageId = this.homes[0]._id;
      console.log('delete user item: ', item);
      console.log('delete user companyId: ', companyId);
      console.log('delete from villageId: ', villageId);
      await this.$apollo.mutate({
        mutation: gql`
          mutation deleteOneCompany ($input: ObjectId!) {
            deleteOneCompany (input: $input) {
              companies {
                companyId
              }
            }
          }`,
        variables: {
          input: companyId,
        },
      });
      this.refreshData();
    },
    foundedData(el) {
      this.founded = el;
    },
  },
};
</script>

<style scoped>
.super-class {
  background: #4b79b6;
}
</style>
