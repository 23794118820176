<template>
  <div>
    <div class="organism-head">
      <h1>Gestion des entreprises</h1>
      <company-form-btn @refresh="shouldRefresh = !shouldRefresh"/>
    </div>
    <company-table :refresh="shouldRefresh"/>
  </div>
</template>

<script>
import CompanyFormBtn from './CompanyFormBtn.vue';
import CompanyTable from './CompanyTable.vue';

export default {
  components: {
    CompanyFormBtn,
    CompanyTable,
  },
  data() {
    return {
      shouldRefresh: false,
    };
  },
};
</script>

<style scoped>
.organism-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
</style>
