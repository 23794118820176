<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          color="indigo"
          style="width:250px;"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>
            mdi-card-account-details-outline
          </v-icon>
          <span>Nouvelle société</span>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Entreprise
        </v-card-title>

        <v-card-text>
          <company-form
            @companyFormSent="handleCompanyForm"
          >
          </company-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CompanyForm from './CompanyForm.vue';

export default {
  components: {
    CompanyForm,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    handleCompanyForm(res) {
      console.log('handleCompanyForm res: ', res);
      this.dialog = false;
      this.$emit('refresh');
    },
  },
};
</script>

<style scoped>
.super-class {
  background: #4b79b6;
}
</style>
