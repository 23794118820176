<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-text-field
        v-model="name"
        :counter="50"
        :rules="nameRules"
        label="Name"
        required
      ></v-text-field>

      <v-text-field
        v-model="logo"
        :counter="1000"
        :rules="logoRules"
        label="Logo"
        required
      ></v-text-field>

      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="E-mail"
        required
      ></v-text-field>

      <v-btn
        :disabled="!valid"
        color="success"
        class="mr-4"
        @click="validate"
      >
        Validate
      </v-btn>

      <v-btn
        color="error"
        class="mr-4"
        @click="reset"
      >
        Reset Form
      </v-btn>

      <v-btn
        color="warning"
        @click="resetValidation"
      >
        Reset Validation
      </v-btn>
    </v-form>
  </div>
</template>

<script>
// import AddCompany from '../graphQL/AddCompany.gql';
import gql from 'graphql-tag';

export default {
  data: () => ({
    valid: true,
    name: '',
    nameRules: [
      (v) => !!v || 'Name is required',
      (v) => (v && v.length <= 50) || 'Name must be less than 50 characters',
    ],
    logo: '',
    logoRules: [
      (v) => !!v || 'Logo is required',
      (v) => (v && v.startsWith('http')) || 'Logo must be a valid url',
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
      this.createCompany();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async createCompany() {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation insertOneCompany ($input: HomeCompanyInsertInput) {
            insertOneCompany (input: $input) {
              _id,
              companies {
                name
                email
                logo
              }
            }
          }`,
        variables: {
          input: {
            name: this.name,
            email: this.email,
            logo: this.logo,
          },
        },
      });
      this.$emit('companyFormSent', res);
    },
  },
};
</script>

<style scoped>
.super-class {
  background: #4b79b6;
}
</style>
